/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon16 from "../../static/favicon-16x16.png";
import favicon32 from "../../static/favicon-32x32.png";
import favicon512 from "../../static/android-chrome-512x512.png";
import safariPinnedTab from "../../static/safari-pinned-tab.svg";
import appleTouchIcon from "../../static/apple-touch-icon.png";
import indexOgImage from "../images/og/og.png";

function SEO({ description, lang, meta, keywords, title }) {

  const keyw = [
    `qobalt`,
    `technologies`,
    `nearshore`,
    `apps`,
    `web apps`,
    `mobile apps`,
    `web`,
    `nearshore`,
    `outsourcing`,
    `merida`,
    `mexico`,
    `yucatan`,
    `digital product agency`,
    `world class`,
    `staff augmentation`,
    `startup`,
    `corporate`,
    `enterprise`,
    `websites`,
    `software agency`,
    `app development`,
    `saas`,
    ...keywords
  ];

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      link={[
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: "icon", type: "image/png", sizes: "144x144", href: `${favicon512}` },
        { rel: "apple-touch-icon", type: "image/png", sizes: "180x180", href: `${appleTouchIcon}` },
        { rel: "mask-icon", color: "#2d89ef", href: `${safariPinnedTab}` },
        { rel: "shortcut icon", type: "image/png", href: `${favicon512}` },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `title`,
          content: title,
        },
        {
          name: `image`,
          content: `${site.siteMetadata.siteUrl}${indexOgImage}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${indexOgImage}`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: `${site.siteMetadata.siteUrl}${indexOgImage}`,
        },
      ]
        .concat(
            keyw.length > 0
            ? {
                name: `keywords`,
                content: keyw.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(
          {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url: site.siteMetadata.siteUrl,
            name: title,
            alternateName: title,
          }
      )}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO
